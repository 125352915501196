<template>
  <div class="">
    <sign-up-modal v-if="hasNewSignupFlow"/>
    <div class="">
      <MasterHeader />
      <div v-if="!isLoaded" class="loader">Loading...</div>
      <div class="container container-wide" v-if="isLoaded">
        <div class="text-center">
          <h4 class="mb-2">Your QR code</h4>
          <p>
            This is your personal Excelerate QR code. <br /><br />
            Use it tat events where Excelerate is present to unlock benefits.
          </p>
          <img
            class="qr_image"
            :src="qrCode"
            alt=""
            srcset=""
          />
          <p>Check out what events we are attending</p>
          <router-link to="/events" class="btn button-pink">Events</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MasterHeader from '../../components/MasterHeader.vue';
import SignUpModal from '../../components/signUpModal.vue';

export default {
  components: { MasterHeader, SignUpModal },
  data() {
    return {};
  },
  computed: {
    ...mapState(['user']),
        hasNewSignupFlow(){
      const vm = this
      const isValid = vm.$store.getters.currentProgress >= 0 ? true : false
      const isStudent = vm.$store.getters.role === 'Student'
        if (!isValid && isStudent) {
          localStorage.setItem('notSignedUp', 1);
        }
        if (isValid) {
          localStorage.removeItem('notSignedUp');
        }
      return true
    },
    isLoaded() {
      if (this.user.role === 'Student') {
        return true;
      } else {
        history.back();
      }
      return true;
    },
    qrCode(){
      if(localStorage.getItem('user_code')){
        return localStorage.getItem('user_code')
      }
      return false
    },
  },
};
</script>

<style>
.qr_image {
  max-width: 300px;
  max-height: 300px;
  margin: 5% 0 10% 0;
}
</style>
